$white:    #fff
$gray-100: #f8f9fa
$gray-200: #e9ecef
$gray-300: #dee2e6
$gray-400: #ced4da
$gray-500: #adb5bd
$gray-600: #6c757d
$gray-700: #495057
$gray-800: #343a40
$gray-900: #212529
$black:    #000

$blue:    #3396ff
$indigo:  #6610f2
$purple:  #951ee9
$pink:    #E91E63
$red:     #dc3545
$orange:  #ff5722
$yellow:  #ffc107
$green:   #28a745
$teal:    #20c997 
$cyan:    #17a2b8 

// --------------------------------------------

//body
    //background-color: #f5f5f5

.page-title
    margin-top: 6px
    padding: 4px 6px
    text-align: center
    color: $gray-500

.wrapper
    padding: 12px 16px
    margin-top: 10px
    margin-bottom: 10px
    width: 100%
    height: 100%
    background-color: $white

.center
    display: flex
    align-items: center
    justify-content: center

.form-group
    margin: 0

@font-face
    font-family: 'digital'
    font-style: normal
    font-weight: 400
    src: url("./fonts/digital.ttf")